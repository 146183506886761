import type { FC } from 'react'
import styled from 'styled-components'
import { vw, mq } from '@/styles'

export const MainNotFound:FC = () => {
  return (
    <Main>
      <Section>
        <h2>La página que estás buscando no existe</h2>
        <p>Intenta navegar por la web para ver si lo encuentras. O a lo mejor descubres algo mucho mejor, quien sabe.</p>
      </Section>
    </Main>
  )
}

const Main = styled.main`
  overflow-x: hidden;
  padding: 0;
  position: relative;

  ${mq.greaterThan('tablet')} {
    overflow-x: inherit;
  }
`

const Section = styled.section`
  grid-column: 1 / span 6;
  padding: ${vw(50, 'mobile')} ${vw(20, 'mobile')};
  align-items: center;
  margin-bottom:${vw(50, 'mobile')} ;

  ${mq.greaterThan('tablet')} {
    grid-column: 5 / span 4;
    padding: ${vw(60, 'desktop')} 0;
    margin-bottom:${vw(60, 'desktop')} ;
  }

  button {
    color: var(--color-white);
    margin-top: ${vw(60, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin-top:${vw(30, 'desktop')} ;
    }
  }

  h2, p {
    font-family: var(--font-work-sans);
    color: var(--color-text);
    padding: 15px;
    text-align: center;
  }

  h2 {
    font-weight: 700 !important;
  }
`
