'use client' // Error components must be Client Components
// import { useEffect } from 'react'
import { MainNotFound } from '@/components/MainNotFound'

export default function Error({  }: {
  error: Error & { digest?: string }
}) {

  // useEffect(() => {
  //   // Log the error to an error reporting service
  //   console.error(error)
  // }, [error])

  return (
    <div className='global-err'>
      <MainNotFound />
    </div>
  )
}
